import { ChangeEvent, useState } from "react";

interface SliderProps {
  min: number;
  max: number;
  step: number;
  value: number;
  onChange?: (value: number) => void;
}

export const Slider = ({
  min,
  max,
  step,
  value,
  onChange = () => {},
}: SliderProps) => {
  const [sliderValue, setSliderValue] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);
    setSliderValue(newValue);
    onChange(newValue);
  };

  // Custom styling for the slider - easier to do it here than in Tailwind
  const sliderStyle = {
    background: `linear-gradient(to right, var(--malibu) 0%, var(--malibu) ${
      ((sliderValue - min) / (max - min)) * 100
    }%, var(--scorpion) ${
      ((sliderValue - min) / (max - min)) * 100
    }%, var(--scorpion) 100%)`,
  };

  // count the amount of marks to display, based on the min, max and step values
  // adds a index to each mark, starting from 1 to be used for the
  const markLabels = Array((max - min) / step + 1).fill(0).map((_, i) => i + 1)

  return (
      <div className="relative flex items-center">
        <input
          defaultValue={sliderValue}
          type="range"
          min={min}
          max={max}
          step={step}
          onChange={handleChange}
          className="z-10 h-[1px] w-full cursor-pointer appearance-none rounded-full bg-scorpion focus:outline-none"
          style={sliderStyle}
        />
        <div className="absolute -bottom-[3.5px] left-0 right-0 flex justify-between">
          {markLabels.map((mark) => (
            <span
              key={mark}
              className={`${
                sliderValue >= mark * step ? "bg-malibu" : "bg-scorpion"
              }  h-2 w-2 rounded-full bg-malibu`}
            />
          ))}
        </div>
      </div>
  );
};
