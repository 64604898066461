import { signal } from "@preact/signals-react";

const user = signal<Record<string, any> | null | undefined>(undefined);
const error = signal<string | null>(null);
const loading = signal<boolean>(false);
const styleOptions = signal<Record<string, any> | null>(null);

fetch(`${process.env.REACT_APP_API_SERVER}/user/style-options`)
  .then((response) => response.json())
  .then((data) => {
    styleOptions.value = data;
  });

const state = new Proxy(
  { user, error, styleOptions, loading },
  {
    get: (target: Record<string, any>, prop: string) => {
      return target[prop].value;
    },
  },
);

const actions = {
  create: async ({ username, context = "" }: { username: string, context: string }) => {
    if (!username || !username.trim())
      return error.value = 'username is required';

    loading.value = true;

    const result = await fetch(
      `${process.env.REACT_APP_API_SERVER}/user/create`,
      {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'x-api-token': process.env.REACT_APP_USER_API_TOKEN,
        } as any,
        body: JSON.stringify({ username, context })
      }
    ).catch((err) => {
      loading.value = false;
      error.value = err;
    });

    const json = await (result as Response).json();

    if (json.error) {
      error.value = json.message;
      return;
    }

    if (json.uuid) {
      localStorage.setItem("uuid", json.uuid);
      user.value = json;
    }

    loading.value = false;
  },
  getUser: async ({ username, uuid }: { username?: string; uuid?: string }) => {
    if (!username && !uuid) return;
    loading.value = true;
    const result = await fetch(
      `${process.env.REACT_APP_API_SERVER}/user/login`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, uuid }),
      },
    ).catch((err) => {
      loading.value = false;
      error.value = err;
    });

    const json = await (result as Response).json();

    if (json.error) {
      error.value = json.message;
      return;
    }

    return json; 
  },
  login: async ({ username, uuid }: { username?: string; uuid?: string }) => {
    if (!username && !uuid) return;
    const result = await actions.getUser({ username, uuid }) as any;
    if (result.uuid) {
      localStorage.setItem("uuid", result.uuid);
      user.value = result;
    }
  },
  logout: () => {
    if (!user.value) return;
    loading.value = true;
    localStorage.removeItem("uuid");
    user.value = null;
    loading.value = false;
  },
  updateStyle: async (style: Record<string, any>) => {
    if (!user.value) return;

    const update = { ...user.value.style };
    Object.keys(update.traits).forEach((key) => {
      update.traits[key] = style[key];
    });
    update.context = style.context || user.value.style.context;
    update.goal = style.goal || user.value.style.goal;
    update.archetype = style.archetype || user.value.style.archetype;

    loading.value = true;
    const result = await fetch(`${process.env.REACT_APP_API_SERVER}/user/style`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ uuid: user.value.uuid, style: update }),
    }).catch((err) => {
      loading.value = false;
      error.value = err;
    });

    const json = await (result as Response).json();
    user.value = json;
    loading.value = false;
  },
  clearError() {
    error.value = null;
  }
};

if (localStorage.getItem("uuid")) {
  const uuid = localStorage.getItem("uuid") || "";
  if (uuid)
    actions.login({ uuid }).then(() => {
      if (!user.value) {
        localStorage.removeItem("uuid");
      }
    });
} else {
  user.value = null;
}

const store = { state, actions };
export default store;
