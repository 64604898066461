import { Routes, Route } from "react-router-dom";
import { CreateContent, NotFound } from "./pages";
import { Auth } from "components";
import { Layout } from "./components/Layout/Layout";

export function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route element={<Auth />}>
          <Route path="generate-content" element={<CreateContent />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
}
