import { signal } from "@preact/signals-react";
import userStore from "../stores/user";

export const platforms = [
  { name: "Twitter", value: "twitter", maxChars: 280 },
  { name: "LinkedIn", value: "linkedin", maxChars: 1300 },
  { name: "Medium", value: "medium", maxChars: 5000 },
  { name: "Email", value: "email", maxChars: 10000 },
  { name: "Facebook", value: "facebook", maxChars: 63206 },
  { name: "Instagram", value: "instagram", maxChars: 2200 },
  { name: "Pinterest", value: "pinterest", maxChars: 500 },
  { name: "TikTok", value: "tiktok", maxChars: 150 },
  { name: "YouTube", value: "youtube", maxChars: 5000 },
  { name: "Reddit", value: "reddit", maxChars: 10000 },
  { name: "Tumblr", value: "tumblr", maxChars: 10000 },
];

export const versions = [ 
  { name: "Version 1", value: 0 },
  { name: "Version 2", value: 1 },
  { name: "Version 3", value: 2 },
  { name: "Version 4", value: 3 },
  { name: "Version 5", value: 4 },
];

const trending = signal<any[]>([]);
const topic = signal<string>("");
const content = signal<string>("");
const loading = signal<boolean>(false);
const platform = signal<any>(platforms[0]);
const version = signal<any>(versions[0]);

const state = new Proxy(
  { 
    trending,
    topic,
    content,
    loading,
    platform,
    version,
  },
  {
    get: (target: Record<string, any>, prop: string) => {
      return target[prop].value;
    },
  },
);

const actions = {
  generateContent: async ({ topic }: { topic: string }) => {
    const { uuid } = userStore.state.user;
    const version = state.version.value;
    const platform = state.platform.name;
    const length = state.platform.maxChars;

    loading.value = true;
    const result = await fetch(`${process.env.REACT_APP_API_SERVER}/generate-content`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ uuid, topic, version, platform, length }),
    }).catch((err) => {
      console.error(err);
      loading.value = false;
    });

    loading.value = false;
    const json = await (result as Response).json();

// console.log(`
// user
// ====
// ${json.messages.find((m: any) => m.role === 'user').content}

// system
// ======
// ${json.messages.find((m: any) => m.role === 'system')?.content || 'empty'}
// `)
    
    return json;
  },
  updateTrendingTopics: async ({ query }: { query?: string } = {}) => {
    loading.value = true;

    if (!query) {
      const cachedTrending = JSON.parse(
        localStorage.getItem("trending") || "{}",
      );

      const isExpired =
        new Date(cachedTrending.updatedAt).getDate() !== new Date().getDate();

      if (cachedTrending?.topics?.length && !isExpired) {
        trending.value = cachedTrending.topics;
        loading.value = false;
        return cachedTrending.topics;
      }
    }

    const result = await fetch(`${process.env.REACT_APP_API_SERVER}/trending-topics`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query }),
    }).catch((err) => {
      loading.value = false;
      console.error(err);
    });

    const json = await (result as Response).json();
    trending.value = json;

    if (!query) {
      localStorage.setItem(
        "trending",
        JSON.stringify({
          topics: json,
          updatedAt: new Date(),
        }),
      );
    }

    loading.value = false;
  },
  updateTopic: (newTopic: string) => {
    topic.value = newTopic;
  },
  updateContent: (newContent: string) => {
    content.value = newContent;
  },
  updatePlatform: (newPlatform: any) => {
    platform.value = newPlatform;
  },
  updateVersion: (newVersion: any) => {
    version.value = newVersion;
  },
};

actions.updateTrendingTopics();

const store = { state, actions };
export default store;
