import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Login } from "../../pages";
import userStore from "../../stores/user";

export const Auth = () => {
  const { user } = userStore.state;
  const navigate = useNavigate();

  useEffect(() => {
    const isRoot = window.location.pathname === "/";
    if (user && isRoot) navigate("/generate-content");
    else if (!user && !isRoot) navigate("/");
  }, [user, navigate]);

  if (user === undefined) return null;
  if (user === null) return <Login />;
  return <Outlet />;
}
