import { cva } from "class-variance-authority";
import { useOnClickOutside } from "hooks";
import { useRef, useState } from "react";
import { BiChevronDown } from "react-icons/bi";

type DropDownProps = {
  label: string;
  children: React.ReactNode;
  autoclose?: boolean;
};

const button = cva("button", {
  variants: {
    open: {
      false: ["border-mineshaft"],
      true: ["border-astronaut"],
    },
  },
});

export const DropDown = ({ label, children, autoclose = true }: DropDownProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) setOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className="relative" ref={ref}>
      <button
        className={`
        ${button({ open })}
        flex items-center gap-2 rounded-2xl border bg-onyx px-2 py-1 text-xs tablet:text-sm
        `}
        onClick={() => setOpen(!open)}
      >
        {label}
        <BiChevronDown className="inline-block text-base tablet:text-xl" />
      </button>
      {open && (
        <div
          className="absolute p-4 right-0 z-10 mt-2 rounded-2xl border border-astronaut bg-onyx"
          onMouseUp={() => autoclose && setOpen(false)}
        >
          {children}
        </div>
      )}
    </div>
  );
};
