import { useState } from "react";
import { Button, Input, Textarea } from "../components";
import userStore from "../stores/user";

export const Login = () => {
  const { error } = userStore.state;
  const { login, create, getUser, clearError, updateStyle } = userStore.actions;
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>("");
  const [context, setContext] = useState<string>("");
  const [register, setRegister] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [style, setStyle] = useState<any>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (register) {
      await create({ username, context });
    } else if (confirm) {
      await login({ username });
      await updateStyle({ ...style, ...style.traits, context });
    } else {
      const result = await getUser({ username }) as any;
      if (result) {
        setStyle(result.style);
        setUsername(result.username);
        setContext(result.style.context);
        setConfirm(true);
      }
      setLoading(false);
    }

    if (register || confirm) {
      setLoading(false);
      setContext("");
      setUsername("");
    }
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="w-full max-w-mobile rounded-2xl border border-mineshaft px-4 py-8">
        <p className="mb-12 text-center text-xl font-black leading-s text-wildsand">
          social<span className="text-malibu">twin</span>
        </p>
        <form className="mb-4" onSubmit={handleSubmit}>
          {confirm ? (
            <h2 className="text-lg text-wildsand font-black text-center mb-2 -mt-6">Welcome back {username}!</h2>
          ) : (
          <Input
            disabled={loading}
            name="username"
            fullWidth
            className="mb-2"
            autoComplete="off"
            corners="rounded"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              clearError();
            }}
          />
          )}
          {(register || confirm) && (
            <Textarea
              disabled={loading}
              light
              placeholder="Tell us about yourself."
              onChange={(e) => {
                setContext(e.target.value);
                clearError();
              }}
              value={context}
            />
          )}
          {error && (
            <p className="mt-2 text-center text-xs font-bold text-danger">
              {error}
            </p>
          )}
          <Button
            className="mt-4"
            fullWidth
            type="submit"
            disabled={loading}
            isLoading={loading}
            isLoadingText="Processing..."
          >
            {register ? "Create Account" : confirm ? "Yup that's me!" : "Login"}
          </Button>
          <button
            type="button"
            className="mx-auto mt-4 block cursor-pointer text-center text-xs text-alto underline hover:text-wildsand"
            onClick={(e) => {
              e.preventDefault();
              clearError();
              setUsername("");
              setContext("");
              setRegister(!(register || confirm));
              setConfirm(false);
            }}
          >
            {!(register || confirm) ? "no account? click here to register" : "back to login"}
          </button>
        </form>
      </div>
    </div>
  );
};
