import { cva, type VariantProps } from "class-variance-authority";

const selectItem = cva("div", {
  variants: {
    isSelected: {
      true: [
        "text-malibu",
        "bg-transparent",
        "border-astronaut",
        "scale-[1.02]",
      ],
      false: ["bg-transparent", "text-alto", "border-mineshaft"],
    },
    disabled: {
      true: ["grayscale", "opacity-30", "pointer-events-none"],
    },
  },
});

export interface SelectItemProps extends VariantProps<typeof selectItem> {
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export const SelectItem = ({
  label,
  icon,
  isSelected,
  disabled,
  onClick,
}: SelectItemProps) => {
  return (
    <div
      className={`flex cursor-pointer items-center gap-2 rounded-2xl border-2 px-4 py-2 text-sm font-semibold transition-all  hover:scale-[1.02] active:scale-100 md:px-6 md:py-4 md:text-base ${selectItem(
        {
          isSelected,
          disabled,
        },
      )}`}
      onClick={onClick}
    >
      {icon && <span className="text-lg md:text-xl">{icon}</span>}
      {label}
    </div>
  );
};
