import { forwardRef } from "react";

import { cva, type VariantProps } from "class-variance-authority";

const input = cva("input", {
  variants: {
    accent: {
      transparent: ["border-mineshaft"],
      primary: ["border-mineshaft bg-onyx"],
    },
    fullWidth: {
      true: "w-full",
    },
    corners: {
      rounded: ["rounded-full"],
      "square-rounded": ["rounded-2xl"],
    },
  },
  defaultVariants: {
    accent: "transparent",
  },
});

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof input> {}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, fullWidth, corners, accent, ...props }, ref) => {
    return (
      <input
        ref={ref}
        className={`${input({
          fullWidth,
          corners,
          accent,
          className,
        })} font-normal tablet:px-6 px-4 tablet:py-4 py-3 tablet:text-base text-sm border leading-xs border-mineshaft focus:border-astronaut placeholder-nevada outline-none disabled:bg-mineshaft`}
        {...props}
      />
    );
  },
);
