interface TrendingTopicsProps {
  topics: string[];
  onClick: (topic: string) => void;
}

export const TrendingTopics = ({ topics, onClick }: TrendingTopicsProps) => {
  return (
    <div className="tablet:top-14 absolute left-0 top-11 max-h-0 w-full overflow-hidden rounded-bl-2xl rounded-br-2xl border-astronaut bg-black text-white transition-all scrollbar-hide peer-focus:max-h-96 peer-focus:overflow-scroll peer-focus:border">
      <div className="tablet:px-6 px-4 py-4">
        <p className="mb-3 text-xs text-alto">Trending topics</p>
        <ul className="tablet:space-y-3 space-y-2">
          {topics.map((topic, index) => (
            <li key={`topic_${index}`} onMouseDown={() => onClick(topic)}>
              <h4 className="tablet:text-base cursor-pointer text-sm opacity-70 transition-opacity hover:opacity-100">
                {topic}
              </h4>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
