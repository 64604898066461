import userStore from "../../stores/user";

export const Navigation = () => {
  const { user } = userStore.state;
  const { logout } = userStore.actions;

  const updateStyle = async (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = async (e) => {
      const style = JSON.parse(e.target?.result as string);
      await userStore.actions.updateStyle(style);
    };
  };

  return (
    <nav className="max-w-tablet mx-auto flex w-full items-center justify-between p-10 py-4">
      <p className="text-2xl font-black text-wildsand">
        social<span className="text-malibu">twin</span>
      </p>
      <ul className="flex gap-4">
        {!user?.style && (
          <li className="relative cursor-pointer text-xs font-bold text-white md:text-sm">
            upload settings
            <input
              type="file"
              accept=".json"
              onChange={updateStyle}
              className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer opacity-0"
            />
          </li>
        )}
        <li
          className="cursor-pointer text-xs font-bold text-white md:text-sm"
          onClick={logout}
        >
          logout
        </li>
      </ul>
    </nav>
  );
};
