// import { DropDown } from "components/DropDown";
// import { LuCheck } from "react-icons/lu";
// import contentStore, { versions } from "../../stores/content";

export const SelectVersion = () => {
  // const { version } = contentStore.state;
  // const { updateVersion } = contentStore.actions;

  return <></>

  // return (
  //   <DropDown label="Version">
  //     {versions.map((option) => (
  //       <button
  //         className="flex w-full cursor-pointer items-center justify-between p-2 text-left text-xs tablet:text-sm whitespace-nowrap"
  //         key={option.value}
  //         onMouseDown={() => {
  //           updateVersion(option)
  //         }}
  //       >
  //         {option.name}
  //         <LuCheck className={`ml-6 inline-block stroke-4 text-green ${version.value === option.value ? 'opacity-full' : 'opacity-0'}`} />
  //       </button>
  //     ))}
  //   </DropDown>
  // );
};
