import { DropDown } from "components/DropDown";
import { LuCheck } from "react-icons/lu";
import contentStore, { platforms } from "../../stores/content";

export const SelectPlatform = () => {
  const { platform } = contentStore.state;
  const { updatePlatform } = contentStore.actions;

  return (
    <DropDown label="Platform">
      {platforms.map((option) => (
        <button
          className="flex w-full cursor-pointer items-center justify-between p-2 text-left text-xs tablet:text-sm whitespace-nowrap"
          key={option.value}
          onMouseDown={() => {
            updatePlatform(option)
          }}
        >
          {option.name}
          <LuCheck className={`ml-6 inline-block stroke-4 text-green ${platform.value === option.value ? 'opacity-full' : 'opacity-0'}`} />
        </button>
      ))}
    </DropDown>
  );
};
