import { cva, type VariantProps } from "class-variance-authority";

import { LoadingSpinner } from "../LoadingSpinner";

const button = cva("button", {
  variants: {
    size: {
      small: [
        "tablet:text-xs",
        "tablet:py-2",
        "tablet:px-4",
        "font-semibold",
        "leading-xs",
        "text-sm",
        "py-1",
        "px-2",
      ],
      medium: [
        "tablet:text-base",
        "tablet:py-4",
        "tablet:px-6",
        "font-semibold",
        "leading-xs",
        "text-sm",
        "py-2",
        "px-4",
      ],
    },
    outlined: {
      true: [
        "bg-transparent",
        "border-2",
        "border-malibu",
        "text-wildsand",
        "hover:bg-malibu",
        "hover:text-wildsand",
      ],
      false: [
        "bg-malibu",
        "text-wildsand",
        "border-transparent",
        "hover:scale-[1.01]",
        "hover:contrast-150",
        "active:scale-100",
      ],
    },
    fullWidth: {
      true: ["w-full"],
    },
    corners: {
      rounded: ["rounded-full"],
      "square-rounded": ["rounded-2xl"],
    },
    disabled: {
      true: ["grayscale", "pointer-events-none"],
    },
    isLoading: {
      true: ["grayscale", "pointer-events-none"],
    },
  },
  defaultVariants: {
    size: "medium",
    corners: "rounded",
    outlined: false,
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {
  disabled?: boolean;
  corners?: "rounded" | "square-rounded";
  isLoading?: boolean;
  isLoadingText?: string;
  outlined?: boolean;
}

export const Button = ({
  className,
  size,
  fullWidth,
  corners,
  disabled,
  isLoading,
  isLoadingText,
  outlined,
  ...props
}: ButtonProps) => (
  <button
    className={`${button({
      size,
      fullWidth,
      corners,
      disabled,
      isLoading,
      outlined,
      className,
    })} transition-all] hover:scale-[1.01] hover:contrast-150 active:scale-100`}
    disabled={disabled}
    {...props}
  >
    {isLoading ? (
      <div role="status">
        <LoadingSpinner />
        <span>{isLoadingText}</span>
      </div>
    ) : (
      props.children
    )}
  </button>
);
