import { Outlet, useLocation } from "react-router-dom";
import { Navigation } from "../Navigation";

export const Layout = () => {
  const location = useLocation();
  const showNav = location.pathname !== "/";
  return (
    <>
      {showNav && <Navigation />}
      <main className="max-w-tablet mx-auto h-full w-full p-10 bg-woodsmoke">
        <Outlet />
      </main>
    </>
  );
};
