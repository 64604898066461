import contentStore from "../../stores/content";
import { Input } from "../../components";
import { TrendingTopics } from "../TrendingTopics";
import { useRef, useState } from "react";

const TOPIC_THROTTLE = 1000;

export const SelectTopic = ({ disabled = false, onSelected }: { disabled?: boolean, onSelected?: (topic: string) => void }) => {
  const { trending, topic } = contentStore.state;
  const { updateTrendingTopics, updateTopic } = contentStore.actions;
  const [ suggestTopic, setSuggestTopic ] = useState<NodeJS.Timeout>();
  const inputRef = useRef<HTMLInputElement>(null);

  const updateSuggestions = async (e: React.ChangeEvent<HTMLInputElement>) => {
    updateTopic(e.target.value);
    clearTimeout(suggestTopic);
    setSuggestTopic(
      setTimeout(async () => {
        const topic = e.target.value;
        await updateTrendingTopics({ query: topic });
        if (inputRef.current) inputRef.current.focus();
      }, TOPIC_THROTTLE),
    );
  };

  return (
    <div className="relative z-10">
      <Input
        ref={inputRef}
        fullWidth
        disabled={disabled}
        accent="primary"
        corners="square-rounded"
        value={topic}
        placeholder="What do you want to write about"
        className="peer relative z-10 transition-all delay-100 ease-linear focus:rounded-bl-none focus:rounded-br-none focus:border-b-transparent focus:bg-black"
        onChange={updateSuggestions}
      />
      {!disabled && (
        <TrendingTopics
          topics={trending.map((t: any) => t.topic)}
          onClick={(topic: string) => {
            updateTopic(topic);
            if (onSelected) onSelected(topic);
          }}
        />
      )}
    </div>
  );
}