import { Button, LoadingSpinner, PageTitle, Textarea } from "../components";
import { SelectTopic } from "modules";
import { SelectPlatform } from "modules/SelectPlatform/SelectPlatform";
import { Nbsp } from "components/Nbsp/Nbsp";
import contentStore from "../stores/content";
import { SelectVersion } from "modules/SelectVersion";
import { UpdateStyles } from "modules/UpdateStyles";

export const CreateContent = () => {
  const { generateContent, updateContent } = contentStore.actions;
  const { topic, content, loading } = contentStore.state;

  return (
    <div className="text-alabaster">
      <div className="mb-4 space-y-6 md:mb-6">
        <PageTitle>Let's Create Some Content!</PageTitle>
        <SelectTopic disabled={loading} />
        {topic && content && (
          <>
            <div className="flex gap-4 justify-end">
              <SelectVersion />
              <SelectPlatform />
              <UpdateStyles />
            </div>
            <Textarea
              value={content}
              onChange={(e) => updateContent(e.target.value)}
            />
          </>
        )}
        {topic && (
          <Button
            disabled={loading}
            onClick={async () => {
              const { content } = await generateContent({ topic });
              updateContent(content);
            }}
          >
            {content ? "Re-Generate" : "Generate"}
            {loading && (
              <><Nbsp many={3} /><LoadingSpinner /></>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};
