import { forwardRef } from "react";

import { TextareaProps } from "./types";

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, minCharacters, maxCharacters, characterCount, light, ...props }, ref) => {
    const maxCharactersWithSeparator = maxCharacters?.toLocaleString();
    const minCharactersWithSeparator = minCharacters?.toLocaleString();
    return (
      <>
        {label && (
          <label className="mb-2 block text-sm font-medium text-alabaster">
            {label}
          </label>
        )}
        <div className="relative flex h-[250px] w-full flex-col md:h-[305px]">
          <textarea
            ref={ref}
            className={[
              "block w-full flex-1 resize-none tablet:px-6 px-4 tablet:py-4 py-3 pb-0 tablet:text-base ",
              "text-sm outline-none scrollbar-hide placeholder:opacity-50 md:p-8 md:pb-0 md:text-base",
                light ?
                 "border-mineshaft w-ful rounded-3xl focus:border-astronaut placeholder-nevada outline-none bg-white disabled:bg-mineshaft" : 
                 "bg-black rounded-2xl border border-mineshaft focus:border-astronaut text-alto"
              ].join(' ')}
            minLength={minCharacters}
            maxLength={maxCharacters}
            {...props}
          />
          {minCharacters && maxCharacters && (
            <span className="p-6 pt-2 text-right text-xs font-bold text-alabaster md:p-8 md:pt-2 md:text-base">
              {minCharactersWithSeparator} - {maxCharactersWithSeparator}
              &nbsp;characters
            </span>
          )}
          {!!characterCount && (
            <span className="absolute -bottom-7 right-0 text-xs font-bold text-alabaster md:text-sm">
              {characterCount}/{maxCharactersWithSeparator}
            </span>
          )}
        </div>
      </>
    );
  },
);
