import { Button, Slider } from "components";
import { DropDown } from "components/DropDown";
import userStore from "../../stores/user";
import { useState } from "react";

export const UpdateStyles = () => {
  const { user, styleOptions, loading } = userStore.state;
  const { updateStyle } = userStore.actions;
  const [traits, setTraits] = useState({
    archetype: user.style.archetype,
    goal: user.style.goal,
    ...user.style.traits,
  });

  if (!styleOptions) return null;

  const update = (style: string, value: number) => {
    setTraits({
      ...traits,
      [style]: allOptions.find((s: any) => s.id === style)?.options[value].id,
    });
  };

  const apply = () => {
    updateStyle(traits);
    // close the dropdown - hacky, should use actions
    document.dispatchEvent(new Event("mousedown"));
  };

  const allOptions = [
    {
      id: 'goal',
      name: 'Goal',
      options: styleOptions.goals
    },
    {
      id: 'archetype',
      name: 'Archetype',
      options: styleOptions.archetypes,
    },
    ...styleOptions.traits, 
  ];

  const middle = Math.floor(allOptions.length / 2);
  const col1 = allOptions.slice(0, middle);
  const col2 = allOptions.slice(middle);

  return (
    <DropDown label="Styles" autoclose={false}>
      <div className="flex gap-2 flex-col">
        {[col1, col2].map((options: any, index) => (
          <div key={`styleColumn_${index}`}>
            {options.map((style: any) => (
              <div key={style.id} className="mb-4 w-72">
                <label className="mb-2 block whitespace-nowrap text-xs tablet:text-sm">
                  {style.name}:{" "}
                  {
                    style.options.find((o: any) => o.id === traits[style.id])
                      ?.name
                  }
                </label>
                <Slider
                  min={0}
                  max={style.options.length - 1}
                  step={1}
                  value={style.options.findIndex(
                    (o: any) => o.id === traits[style.id],
                  )}
                  onChange={(value) => update(style.id, value)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Button
        disabled={loading}
        fullWidth
        size="small"
        className="mt-4"
        onClick={apply}
      >
        Apply
      </Button>
    </DropDown>
  );
};
